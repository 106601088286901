import DatePicker from 'vue2-datepicker';
export default {
    name: 'manualSearch',
    components: {
        DatePicker,
    },
    data() {
        return {
            loader: false,
            unsubscribe: null,
            invoiceNumber: null,
            transactionNum: null,
            dateFrom: null,
            dateTo: null,
            manualSearchData: [],
            manualSearchFields: [
                {
                    key: 'select'
                },
                {
                    key: 'first_party_gstn',
                    label: 'First Party GSTN'
                },
                {
                    key: 'third_party_gstn',
                    label: 'Third Party GSTN'
                },
                {
                    key: 'invoice_number'
                },
                {
                    key: 'supplier_name'
                },
                {
                    key: 'transaction_type'
                },
                {
                    key: 'invoice_date'
                },
                {
                    key: 'taxable_amount'
                },
                {
                    key: 'igst',
                    label: 'IGST'
                },
                {
                    key: 'cgst',
                    label: 'CGST'
                },
                {
                    key: 'sgst',
                    label: 'SGST'
                },
                {
                    key: 'cess',
                    label: 'CESS'
                },
                {
                    key: 'tax_rate'
                },
                {
                    key: 'tax_type'
                },
                {
                    key: 'tax_rate'
                }
            ]
        };
    },
    mounted() {},
    methods: {
        journalMatchList() {

        },
    },
};