import DatePicker from 'vue2-datepicker';
import { ModelSelect } from 'vue-search-select';
// import itcReconciliationModal from './';
import ReconcileDetails from './reconcileDetails';
import Available from './available';
import PaymentView from './paymentView';
import ForegoDetails from './foregoDetails';
import RecoErrors from './recoError';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'gstrTwoASixADetails',
  components: {
    ModelSelect,
    DatePicker,
    ReconcileDetails,
    Available,
    PaymentView,
    ForegoDetails,
    RecoErrors,
    commonHelper
    // itcReconciliationModal
  },
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.getItcReconciliation(this.searchParams);
      } else {
        this.gstrTwoASixADetailsData = [];
      }
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage - 1;
      this.getItcReconciliation(this.searchParams);
    }
  },
  props: ['searchParams', 'inv_num'],
  data() {
    return {
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      payload: null,
      unsubscribe: null,
      loader: false,
      showModal: false,
      tab: null,
      // showReconcileDetailsModal: false,
      reconcileDetails: null,
      // showAvailableModal: false,
      availableDetails: null,
      paymentViewDetails: null,
      foregoDetails: null,
      recoErrorDetails: null,
      gstrTwoASixADetailsData: [
        {
          gstr2agstr6a_remark: '',
          reconcile_details: '',
          available: '',
          payment_view: '',
          forego_details: '',
          reco_errors: ''
        }
      ],
      gstrTwoASixADetailsFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'unclaimed_itc',
          stickyColumn: true,
          class: 'col-fix',
          variant: 'warning'
        },
        {
          key: 'period_name',
          class: 'col-fix'
        },
        {
          key: 'gstn_no_supp',
          label: 'GSTN Supplier',
          class: 'col-fix'
        },
        // {
        //   key: 'reco_type',
        //   label: 'Reconcile Type',
        //   class: 'col-fix'
        // },
        {
          key: 'suplier_name',
          label: 'Supplier Name'
        },
        {
          key: 'invoice_number',
          class: 'col-fix'
        },
        {
          key: 'invoice_date',
          label: 'Invoice Date',
          class: 'col-fix'
        },
        {
          key: 'invoice_value',
          stickyColumn: true,
          class: 'col-fix'
        },
        {
          key: 'taxable_value',
          stickyColumn: true,
          class: 'col-fix'
        },
        {
          key: 'igst_amount',
          stickyColumn: true,
          class: 'col-fix'
        },
        {
          key: 'cgst_amount',
          label: 'CGST Amount',
          stickyColumn: true,
          class: 'col-fix'
        },
        {
          key: 'sgst_amount',
          label: 'SGST Amount',
          stickyColumn: true,
          class: 'col-fix'
        },
        {
          key: 'cess_amount',
          label: 'CESS',
          stickyColumn: true,
          class: 'col-fix'
        },
        {
          key: 'rate',
          stickyColumn: true,
          class: 'col-fix'
        },
        {
          key: 'invoice_type',
          stickyColumn: true,
          class: 'col-fix'
        },
        {
          key: 'erp_taxable_value',
          stickyColumn: true,
          class: 'col-fix'
        },
        {
          key: 'erp_igst_amount',
          label: 'IGST Amount',
          stickyColumn: true,
          class: 'col-fix'
        },
        {
          key: 'erp_cgst_amount',
          label: 'CGST Amount',
          stickyColumn: true,
          class: 'col-fix'
        },
        {
          key: 'erp_sgst_amount',
          label: 'SGST Amount',
          stickyColumn: true,
          class: 'col-fix'
        },
        {
          key: 'itc_type',
          label: 'ITC Type',
          stickyColumn: true
        },
        {
          key: 'status',
          stickyColumn: true,
          class: 'col-fix'
        },
        {
          key: 'source_type',
          stickyColumn: true,
          class: 'col-fix'
        },
        {
          key: 'dtl_id',
          stickyColumn: true,
          class: 'col-fix'
        },
        {
          key: 'pos',
          stickyColumn: true,
          class: 'col-fix'
        },
        {
          key: 'gstr2agstr6a_remark',
          label: 'GSTR2A/GSTR6A Remark',
          class: 'col-fix'
        },
        {
          key: 'reconcile_details',
          class: 'col-fix'
        },
        {
          key: 'available',
          class: 'col-fix'
        },
        {
          key: 'payment_view',
          class: 'col-fix'
        },
        {
          key: 'forego_details',
          class: 'col-fix'
        },
        {
          key: 'reco_errors',
          class: 'col-fix'
        }
      ],
      dtlId: null,
      invoiceNum: null
    };
  },
  mounted() {
    this.invoiceNum = this.inv_num;
    this.getItcReconciliation();
  },
  methods: {
    getItcReconciliation(params) {
      let payload = this.searchParams;
      if (params) {
        payload = Object.assign(payload, params);
      }
      this.loader = true;
      this.$store
        .dispatch('itcReconciliation/getItcReconciliation', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.gstrTwoASixADetailsData = response.data.data.page.map(data => {
              data.selectBox = false;
              return data;
            });
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateGstReco() {
      const filterData = this.gstrTwoASixADetailsData.filter(key => key.select);
      const itcReconciliation = filterData.map(elem => {
        return {
          dtl_id: elem.dtl_id,
          remark: elem.gstr2agstr6a_remark,
          cnr: elem.unclaimed_itc
        };
      });
      const payload = {
        data: itcReconciliation ? itcReconciliation : null
      };
      this.loader = true;
      this.$store
        .dispatch('itcReconciliation/updateGstReco', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.gstrTwoASixADetailsData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ReconcileDetails') {
        return (this.childName = 'Reconcile Details');
      } else if (tab === 'Available') {
        return (this.childName = 'Available');
      } else if (tab === 'PaymentView') {
        return (this.childName = 'Payment View');
      } else if (tab === 'ForegoDetails') {
        return (this.childName = 'Forego Details');
      } else if (tab === 'RecoErrors') {
        return (this.childName = 'Reco Errors');
      }
    },
    openShowModal(flag, component, item) {
      this.showModal = flag;
      if (component && item) {
        this.tab = component;
        this.dtlId = item.dtl_id;
      }
      this.setChildName(this.tab);
    },
    selectBoxChecked(flag, index) {
      this.gstrTwoASixADetailsData[index].unclaimed_itc = flag;
    }
  },
  beforeDestroy() {
    // this.unsubscribe();
  }
};
