import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'reconcileDetails',
  components: {
    appStrings
  },
  props: ['dtlId', 'legalEntityId'],
  data() {
    return {
      alert: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      parent_value_set_id: null,
      recoIndex: null,
      setTimeVsetCode: null,
      showValueSetModal: false,
      totalRows: null,
      currentPage: 1,
      payload: null,
      unsubscribe: null,
      loader: false,
      reconcileDetailsData: [],
      reconcileDetailsFields: [
        {
          key: 'line_num',
          label: 'Line Number'
        },
        {
          key: 'gstn_no',
          label: 'GSTN No'
        },
        {
          key: 'gstn_no_supp',
          label: 'GSTN No Supplier'
        },
        {
          key: 'suplier_name',
          label: 'Supplier Name'
        },
        {
          key: 'invoice_number'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'invoice_value'
        },
        {
          key: 'taxable_value'
        },
        {
          key: 'igst_amount',
          label: 'IGST Amount'
        },
        {
          key: 'cgst_amount',
          label: 'CGST Amount'
        },
        {
          key: 'sgst_amount',
          label: 'SGST Amount'
        },
        {
          key: 'status'
        },
        {
          key: 'rate'
        },
        {
          key: 'period_name'
        },
        {
          key: 'source_type'
        },
        {
          key: 'dtl_id'
        },
        {
          key: 'pos'
        },
        {
          key: 'submited_status'
        },
        {
          key: 'invoice_type'
        }
      ],
      reconcileGridData: [],
      reconcileGridFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'source',
          label: 'Source'
        },
        {
          key: 'fp_gst',
          label: 'First Party GST'
        },
        {
          key: 'tp_gst',
          label: 'Third Party GST'
        },
        {
          key: 'erpinvoice_no',
          label: 'Book Invoice Number'
        },
        // {
        //   key: 'erp_gl_date',
        //   label: 'Book GL Date'
        // },
        {
          key: 'erpinvoice',
          label: 'Book Invoice Date'
        },
        {
          key: 'gstr3b_period',
          label: ' 3B Period'
        },
        {
          key: 'taxtable',
          label: 'Taxable Value'
        },
        // {
        //   key: 'erp_gl_date',
        //   label: 'GL Date'
        // },
        // {
        //   key: 'erp_invoice_date',
        //   label: 'Invoice Date'
        // },
        {
          key: 'igst',
          label: 'IGST Amount'
        },
        {
          key: 'cgst',
          label: 'CGST Amount'
        },
        {
          key: 'sgst',
          label: 'SGST Amount'
        },
        // {
        //   key: 'reference_type'
        // },
        {
          key: 'status'
        },
        {
          key: 'tax_rate_percentage',
          label: 'Tax Rate Percentage',
          class: 'text-center'
        },
        {
          key: 'current_record_flag',
          class: 'text-center'
        },
        {
          key: 'remarks'
        },
        {
          key: 'date',
          label: 'Creation Date',
          class: 'text-center'
        }
      ],
      selectedPoData: null
    };
  },
  mounted() {
    this.getReconcileDetails();
    this.getReconcileGridDetails();
  },
  methods: {
    getReconcileDetails() {
      this.loader = true;
      this.$store
        .dispatch('itcReconciliation/getReconcileDetails', this.dtlId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.reconcileDetailsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedPORow(item) {
      this.selectedPoData = item;
    },
    unReconcileData() {
      const payload = {
        invoice_id: this.selectedPoData.reference_id,
        rate_pr: this.selectedPoData.tax_rate_percentage,
        dtl_id: this.selectedPoData.dtl_id,
        source: this.selectedPoData.source
      };
      this.loader = true;
      this.$store
        .dispatch('itcReconciliation/unRecoTabAction', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$bvToast.toast(response.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
            this.getReconcileGridDetails();
          } else {
            this.$bvToast.toast(response.response.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getReconcileGridDetails() {
      const payload = {
        dtl_id: this.dtlId,
        le_id: this.legalEntityId
      };
      this.loader = true;
      this.$store
        .dispatch('itcReconciliation/getReconcileGridDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.reconcileGridData = response.data.data.page;
            this.reconcileGridData.forEach(ele => {
              ele.erpinvoice = commonHelper.convertDateFormat(ele.erpinvoice);
            });
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    convertCreationDateFormat(dateStr) {
      const dateParts = dateStr.split('-');
      const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
      return formattedDate;
    },
    // unReconcileAction() {
    //   const filterData = this.reconcileGridData.filter(key => key.selectBox);
    //   if (filterData.length) {
    //     const data = filterData.map(key => {
    //       return {
    //         reco_id: key.reco_id,
    //         period_3b: key.gstr3b_period,
    //         remark: key.remarks,
    //         dtl_id: key.dtl_id
    //       };
    //     });
    //     const payload = {
    //       action: '-1',
    //       itc_type: this.reconcileDetailsData[0].itc_type,
    //       data: data
    //     };
    //     this.loader = true;
    //     this.$store
    //       .dispatch('itcReconciliation/changeRecoAction', payload)
    //       .then(response => {
    //         this.loader = false;
    //         if (response.status === 200) {
    //           this.reconcileGridData = response.data.data;
    //           this.totalRows = response.data.data.total_elements;
    //           this.$bvToast.toast(response.data.message, {
    //             title: 'Alert',
    //             variant: 'success',
    //             solid: true
    //           });
    //         } else {
    //           this.$bvToast.toast(response.response.data.message, {
    //             title: 'Alert',
    //             variant: 'danger',
    //             solid: true
    //           });
    //         }
    //       })
    //       .catch(() => {
    //         this.loader = false;
    //       });
    //   } else {
    //     this.$bvToast.toast('Please select atleast one record', {
    //       title: 'Alert',
    //       variant: 'danger',
    //       solid: true
    //     });
    //   }
    // },
    updateAction() {
      if (this.selectedPoData) {
        const selectedData = [
          {
            // reco_id: this.selectedPoData.reco_id,
            source: this.selectedPoData.source,
            period_3b: this.selectedPoData.gstr3b_period,
            dtl_id: this.selectedPoData.dtl_id,
            tax_rate_percentage: this.selectedPoData.tax_rate_percentage,
            reference_id: this.selectedPoData.reference_id
          }
        ];
        const payload = {
          action: '1',
          itc_type: this.reconcileDetailsData[0].itc_type,
          data: selectedData ? selectedData : null
        };
        this.loader = true;
        this.$store
          .dispatch('itcReconciliation/changeRecoAction', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.getReconcileGridDetails();
              this.$bvToast.toast(response.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
            } else {
              this.$bvToast.toast(response.response.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    openValueSetModal(vsetCode, index) {
      this.showValueSetModal = true;
      this.recoIndex = index;
      this.parent_value_set_id = this.reconcileGridData[index].le_id;
      this.vsetCode = vsetCode;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.GSTR3B_PERIOD) {
        this.reconcileGridData[this.recoIndex].gstr3b_period = item.value_code;
      }
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    }
  }
};
