import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'invoiceMatch',
  props: ['availableData', 'searchData'],
  components: {
    DatePicker,
    commonHelper
  },
  watch: {
    availableData: function() {
      if (this.availableData && this.availableData.length > 0) {
        this.recoData = this.availableData[0];
        this.itcType = this.recoData.itc_type;
        this.fpGst = this.recoData.gstn_no;
        this.tpGst = this.recoData.gstn_no_supp;
        this.dtl_id = this.recoData.dtl_id;
        this.invoiceNumber = this.recoData.invoice_number;
      }
    },
    currentPage: function() {
      this.getGstAvaliableTab();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getGstAvaliableTab();
    }
  },
  data() {
    return {
      parent_value_set_id: null,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      showValueSetModal: false,
      invoiceNumber: null,
      loader: false,
      unsubscribe: null,
      sourceType: {
        text: null,
        value: null
      },
      docNumTo: null,
      invoiceDateFrom: null,
      invoiceDateTo: null,
      amountFrom: null,
      amountTo: null,

      invoiceMatchData: [
        {
          period_3b: null,
          period_3b_vset: null
        }
      ],
      invoiceMatchFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        // {
        //   key: 'period_3b',
        //   label: 'Period 3B'
        // },
        {
          key: 'period_name'
        },
        {
          key: 'source_type'
        },
        {
          key: 'fp_gst',
          label: 'First Party GST'
        },
        {
          key: 'tp_gst',
          label: 'Third Party GST'
        },

        // {
        //   key: 'remark'
        // },
        {
          key: 'invoice_num',
          label: 'Invoice Number'
        },
        {
          key: 'tax_rate_percentage',
          class: 'text-center'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'invoice_amount'
        },

        // {
        //   key: 'gl_date',
        //   label: 'GL Date'
        // },
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_num',
          label: 'Vendor Number'
        },
        // {
        //   key: 'trx_type'
        // },
        {
          key: 'taxable_amount'
        },
        {
          key: 'igst',
          label: 'IGST'
        },
        {
          key: 'sgst',
          label: 'SGST'
        },
        {
          key: 'cess',
          label: 'CESS'
        }
      ],
      itcType: null,
      recoData: null,
      fpGst: null,
      tpGst: null,
      specIndex: null,
      page: null,
      selectedInvRow: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      tpGstnSupp: null
    };
  },
  mounted() {
    this.invoiceNumber = this.searchData.invoiceNum;
    if (this.availableData && this.availableData.length > 0) {
      this.recoData = this.availableData[0];
      this.itcType = this.recoData.itc_type;
      this.fpGst = this.recoData.gstn_no;
      this.tpGst = this.recoData.gstn_no_supp;
      this.dtl_id = this.recoData.dtl_id;
      this.invoiceNumber = this.recoData.invoiceNumber;
    }
  },
  methods: {
    invoiceMatchList() {},
    getGstAvaliableTab() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        source: this.sourceType.text,
        // source_type: this.sourceType.text,
        fp_gst: this.availableData.gstn_no,
        gstn_no_supplier: this.tpGstnSupp,
        // tp_gst: this.availableData.gstn_no_supp,
        // itc_type: this.availableData.itc_type,
        le_id: this.searchData.legalEntityId,
        invoice_num: this.invoiceNumber,
        //invoice_num:this.invoice_number,
        invoice_date_from: this.invoiceDateFrom,
        invoice_date_to: this.invoiceDateTo
      };
      this.loader = true;
      this.$store
        .dispatch('itcReconciliation/getGstAvaliableTab', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.invoiceMatchData = response.data.data.page;
            this.invoiceMatchData.map(ele => {
              ele.period_3b = null;
              ele.period_3b_vset = null;
            });
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    // getGstAvaliableTabAction() {
    //   if (this.selectedRow.length) {
    //     const selectedData = this.selectedRow.map(key => {
    //       return {
    //         unrec_detail_id: key.unrec_detail_id,
    //         period_2b: key.period_name ? key.period_name : null,
    //         period_3b: key.period_3b ? key.period_3b : null,
    //         remarks: key.remark ? key.remark : null
    //       };
    //     });
    //     const payload = {
    //       action: 1,
    //       itc_type: this.availableData.itc_type,
    //       dtl_id: this.availableData.dtl_id,
    //       data: selectedData ? selectedData : null
    //     };
    //     this.loader = true;
    //     this.$store
    //       .dispatch('itcReconciliation/getGstAvaliableTabAction', payload)
    //       .then(response => {
    //         this.loader = false;
    //         // this.showAlert = true;
    //         if (response.status === 201) {
    //           this.invoiceMatchData = response.data.data.page;
    //           this.totalRows = response.data.data.total_elements;
    //           // this.editMode = false;
    //           // this.isSuccess = true;
    //           // this.responseMsg = response.data.message;
    //           this.$bvToast.toast(response.data.message, {
    //             title: 'Alert',
    //             variant: 'success',
    //             solid: true
    //           });
    //         } else {
    //           // this.isSuccess = false;
    //           // this.showAlert = true;
    //           // this.responseMsg = response.response.data.message;
    //           this.$bvToast.toast(response.response.data.message, {
    //             title: 'Alert',
    //             variant: 'danger',
    //             solid: true
    //           });
    //         }
    //       })
    //       .catch(() => {
    //         this.loader = false;
    //       });
    //   } else {
    //     // alert('Please Select atleast one record');
    //     this.$bvToast.toast('Please select atleast one record', {
    //       title: 'Alert',
    //       variant: 'danger',
    //       solid: true
    //     });
    //   }
    // },
    saveGstAvaliableTabData() {
      if (this.selectedInvRow) {
        const payload = {
          invoice_id: this.selectedInvRow.invoice_id,
          rate_pr: this.selectedInvRow.tax_rate_percentage,
          dtl_id: this.searchData.dtl_id,
          source: this.selectedInvRow.source
        };
        this.loader = true;
        this.$store
          .dispatch('itcReconciliation/updateGstAvaliableTab', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.getGstAvaliableTab();
              this.$bvToast.toast(response.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
            } else {
              this.$bvToast.toast(response.response.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    selectedInvoiceRow(item) {
      this.selectedInvRow = item;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'GST_INV_SOURCE_TYPE') {
        this.sourceType = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === 'GL_PERIOD') {
        this.invoiceMatchData[this.specIndex].period_3b = item.value_code;
        this.invoiceMatchData[this.specIndex].period_3b_vset =
          item.value_set_dtl_id;
      }
      this.invoiceMatchData = JSON.parse(JSON.stringify(this.invoiceMatchData));
    },
    openValueSetModal(vsetCode, item, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === 'GL_PERIOD') {
        this.parent_value_set_id = item.le_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.sourceType.value) {
        this.sourceType = {
          text: null,
          value: null
        };
      } else if (vsetCode === 'tpGstnSupp') {
        this.tpGstnSupp = null;
      }
    },
    clear() {
      this.sourceType = {
        text: null,
        value: null
      };
      this.invoiceDateFrom = null;
      this.invoiceDateTo = null;
      this.invoiceNumber = null;
      this.invoiceMatchData = [];
    }
  }
};
