import DatePicker from 'vue2-datepicker';
export default {
  name: 'nearMatch',
  components: {
    DatePicker
  },
  data() {
    return {
      loader: false,
      unsubscribe: null,
      nearMatchData: [],
      nearMatchFields: [
        {
          key: 'select'
        },
        {
          key: 'reference_type'
        },
        {
          key: 'receipt_number'
        },
        {
          key: 'gstn_vendor',
          label: 'GSTN Vendor'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'invoice_number'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'tax_invoice_number'
        },
        {
          key: 'tax_invoice_date'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'voucher_number'
        },
        {
          key: 'invoice_amount'
        },
        {
          key: 'taxable_amount'
        },
        {
          key: 'igst',
          label: 'IGST'
        },
        {
          key: 'cgst',
          label: 'CGST'
        },
        {
          key: 'sgst',
          label: 'SGST'
        },
        {
          key: 'cess',
          label: 'CESS'
        },
        {
          key: 'tax_rate'
        },
        {
          key: 'remark'
        }
      ]
    };
  },
  mounted() {},
  methods: {
    nearMatchList() {}
  }
};
