import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
// import store from '../../../../../../store';
import DatePicker from 'vue2-datepicker';
import { ModelSelect } from 'vue-search-select';
import GstrTwoASixADetails from './gstrTwoASixADetails';
// import advanceSearch from './advanceSearch';
import available from './gstrTwoASixADetails/available';
import paymentView from './gstrTwoASixADetails/paymentView';
import foregoDetails from './gstrTwoASixADetails/foregoDetails';
import recoError from './gstrTwoASixADetails/recoError';
import reconcileDetails from './gstrTwoASixADetails/reconcileDetails';
import dropdown from '../../../../shared/dropdown';
import books from './books';
import ims from './ims';
export default {
  name: 'itcReconciliation',
  components: {
    // advanceSearch,
    ModelSelect,
    DatePicker,
    GstrTwoASixADetails,
    available,
    paymentView,
    foregoDetails,
    recoError,
    reconcileDetails,
    books,
    dropdown,
    ims
  },
  watch: {
    selectedLegalEntity: function() {
      this.parent_value_set_id = this.selectedLegalEntity.value;
    },
    perPage: function() {
      this.currentPage = 1;
      this.itcReconciliationGridViewList();
    }
  },
  props: ['rowDetails', 'searchFilters'],
  data() {
    return {
      params: null,
      dtl_id: null,
      requestParams: {},
      searchParams: {},
      payload: null,
      showChildModal: false,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      showModal: false,
      tab: null,
      gstrTwoASixADetails: null,
      showValueSetModal: false,
      showAdvSearchModal: false,
      setTimeVsetCode: null,
      vsetCode: null,
      parent_value_set_id: null,
      invoiceNumber: null,
      supplierGstn: null,
      activeTab: 'gstrTwoASixADetails',
      gstRecoType: {
        value: null,
        text: null
      },
      selectedLegalEntity: {
        value: null,
        text: null
      },
      selectedITCType: {
        value: '2B',
        text: '2B'
      },
      selectedType: {
        value: 'Reconciliation',
        text: 'Reconciliation'
      },
      selectedGSTNumber: {
        value: null,
        text: null
      },
      selectedPeriodFrom: {
        value: null,
        text: null
      },
      glDateFrom: null,
      selectedPeriodTo: {
        value: null,
        text: null
      },
      glDateTo: null,
      periodType: null,
      legalEntityList: [],
      reconcileStatus: {
        value: null,
        text: null
      },
      GSTNTypeList: [
        {
          value: 'GST',
          text: 'GST'
        },
        {
          value: 'ISD',
          text: 'ISD'
        }
      ],
      ITCTypeList: [
        {
          value: '2A',
          text: '2A'
        },
        {
          value: '2B',
          text: '2B'
        },
        {
          value: '6A',
          text: '6A'
        }
      ],
      typeList: [
        {
          value: 'Reconciliation',
          text: 'Reconciliation'
        },
        {
          value: 'Updation',
          text: 'Updation'
        },
        {
          value: 'Forego',
          text: 'Forego'
        },
        {
          value: 'DN(Debit Note)',
          text: 'DN(Debit Note)'
        },
        {
          value: 'ITC Eligible',
          text: 'ITC Eligible'
        },
        {
          value: 'Reclaim',
          text: 'Reclaim'
        }
      ],
      itcReconciliationData: [],
      itcReconciliationFields: [],
      routeName: this.$router.currentRoute.name,
      viewFlag: false,
      operatingUnit: null,
      vendorName: null,
      vendorPan: null,
      correctInvoice: null,
      voucherNumber: null,
      reco_flag: false,
      itc_claimed: null,
      itc_unclaimed: null,
      invTypeLookup: {
        value: null,
        text: null
      },
      defaultValue: {
        value: null,
        text: null
      },
      recoverableFlag: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      supplierName: null,
      showExcelUploadModal: false
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          if (this.activeTab === 'gstrTwoASixADetails') {
            const downloadpayload = { ...this.requestParams };
            this.downloadExcel.downloadData(
              downloadpayload,
              'itcReconciliation/getItcReconciliation',
              'GSTR-6A-Details',
              () => (this.loader = false)
            );
          } else if (this.activeTab === 'books') {
            const downloadpayload = { ...this.requestParams };
            this.downloadExcel.downloadData(
              downloadpayload,
              'itcReconciliation/getBooks',
              'Books',
              () => (this.loader = false)
            );
          } else if (this.activeTab === 'ims') {
            const downloadpayload = { ...this.requestParams };
            this.downloadExcel.downloadData(
              downloadpayload,
              'itcReconciliation/getImsData',
              'IMS',
              () => (this.loader = false)
            );
          } 
        } 
        if (actionName === 'upload') {
          if(this.activeTab === 'ims'){
          this.showExcelUploadModal = true;
          }
        }
      }
    });
    if (this.$router.currentRoute.name === 'compDash') {
      if (this.searchFilters) {
        this.selectedLegalEntity = this.searchFilters.leName;
        this.selectedPeriodFrom = this.searchFilters.period_from;
        this.selectedPeriodTo = this.searchFilters.period_to;
        this.invoiceNumber = this.searchFilters.invoice_num;
        this.selectedGSTNumber.value = this.searchFilters.gst_number;
        this.selectedGSTNumber.text = this.searchFilters.gst_number;
        this.supplierGstn = this.rowDetails.vendor_gstn;

        this.itcReconciliationGridViewList();
      }
    } else if (this.$router.currentRoute.name === 'performanceMatrix') {
      this.selectedLegalEntity = {
        value: this.searchFilters.le_id,
        text: this.searchFilters.leName
      };
      this.selectedPeriodFrom = {
        value: this.searchFilters.period_from,
        text: this.searchFilters.selectedPeriodFrom
      };
      this.selectedPeriodTo = {
        value: this.searchFilters.period_to,
        text: this.searchFilters.selectedPeriodTo
      };
      this.selectedGSTNumber = {
        value: this.rowDetails.fp_gst,
        text: this.rowDetails.fp_gst
      };
      this.supplierGstn = this.rowDetails.vendor_gstn;

      this.itcReconciliationGridViewList();
    }
    // this.getLegalEntity();
    // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //   if (mutation.type === 'shared/setActionName') {
    //     const actionName = state.shared.actionName;
    //     if (actionName === 'add') {
    //       this.showChildModal = true;
    //     }
    //   }
    // });
  },
  methods: {
    viewMore() {
      this.viewFlag = !this.viewFlag;
    },
    itcReconciliationGridViewList() {
      if (this.activeTab === 'gstrTwoASixADetails') {
        if (
          this.selectedLegalEntity.value !== null &&
          this.selectedITCType !== null &&
          this.selectedGSTNumber.value !== null &&
          this.selectedPeriodFrom.value !== null &&
          this.selectedPeriodTo.value !== null
        ) {
          this.requestParams = {
            _page: this.currentPage - 1,
            _limit: this.perPage
          };
          if (!this.params) {
            this.requestParams = {
              _page: this.currentPage - 1,
              _limit: this.perPage,
              le_id: this.selectedLegalEntity.value,
              gst_number: this.selectedGSTNumber.value,
              itc_type: this.selectedITCType.value,
              period_from: this.selectedPeriodFrom.value,
              period_to: this.selectedPeriodTo.value,
              reconcile_status: this.reconcileStatus.value,
              invoice_number: this.invoiceNumber ? this.invoiceNumber : null,
              gstn_no_supp: this.supplierGstn ? this.supplierGstn : null,
              reco_type: this.gstRecoType.value,
              supplier_name: this.supplierName
            };
          } else {
            this.requestParams = Object.assign(this.requestParams, this.params);
          }
          this.searchParams = this.requestParams;
        } else {
          alert('Please fill in all required fields!');
        }
      } else if (this.activeTab === 'books') {
        if (
          this.selectedLegalEntity.value !== null &&
          this.selectedPeriodFrom.value !== null &&
          this.selectedPeriodTo.value !== null
        ) {
          this.requestParams = {
            _page: this.currentPage - 1,
            _limit: this.perPage
          };
          if (!this.params) {
            this.requestParams = {
              _page: this.currentPage - 1,
              _limit: this.perPage,
              le_id: this.selectedLegalEntity.value,
              first_party_gst: this.selectedGSTNumber.value,
              period_from: this.selectedPeriodFrom.value,
              period_to: this.selectedPeriodTo.value,
              reconciliation_status: this.reconcileStatus.value,
              invoice_number: this.invoiceNumber ? this.invoiceNumber : null,
              // third_party_gst : this.supplierGstn ? this.supplierGstn : null,
              operating_unit: this.ouName.value,
              vendor_name: this.vendorName,
              vendor_pan: this.vendorPan,
              doc_sequence_value: this.voucherNumber,
              reco_flag_2b: this.recoverableFlag.value,
              invoice_type_lookup: this.invTypeLookup.value,
              Itc_claimed: this.itc_unclaimed,
              Itc_unclaimed: this.itc_claimed
            };
          } else {
            this.requestParams = Object.assign(this.requestParams, this.params);
          }
          this.searchParams = this.requestParams;
        } else {
          alert('Please fill in all required fields!');
        }
      } else if (this.activeTab === 'ims') {
        if (
          this.selectedLegalEntity.value !== null &&
          this.selectedGSTNumber.value !== null &&
          this.selectedPeriodFrom.value !== null &&
          this.selectedPeriodTo.value !== null
        ) {
          this.requestParams = {
            _page: this.currentPage - 1,
            _limit: this.perPage
          };
          if (!this.params) {
            this.requestParams = {
              _page: this.currentPage - 1,
              _limit: this.perPage,
              le_id: this.selectedLegalEntity.value,
              gst_number: this.selectedGSTNumber.value,
              periodfrom: this.selectedPeriodFrom.value,
              periodto: this.selectedPeriodTo.value,
              invoice_no: this.invoiceNumber ? this.invoiceNumber : null,
              gst_supp: this.supplierGstn ? this.supplierGstn : null,
            };
          } else {
            this.requestParams = Object.assign(this.requestParams, this.params);
          }
          this.searchParams = this.requestParams;
        } else {
          alert('Please fill in all required fields!');
        }
      }
    },

    setChildName(tab) {
      if (tab === 'ReconcileDetails') {
        return (this.childName = 'Reconcile Details');
      } else if (tab === 'Available') {
        return (this.childName = 'Available');
      } else if (tab === 'PaymentView') {
        return (this.childName = 'Payment View');
      } else if (tab === 'ForegoDetails') {
        return (this.childName = 'Forego Details');
      } else if (tab === 'RecoErrors') {
        return (this.childName = 'Reco Errors');
      }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.ouName = {
          value: null,
          text: null
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.selectedGSTNumber = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (this.periodType === 'periodFrom') {
          this.selectedPeriodFrom = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        } else if (this.periodType === 'periodTo') {
          this.selectedPeriodTo = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        }
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_RECONCILATION_STATUS
      ) {
        this.reconcileStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GST_RECO_TYPE) {
        this.gstRecoType = {
          text: item.value_meaning,
          value: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_INVOICE_LOOKUP_TYPE
      ) {
        this.invTypeLookup = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_RECONCILATION_STATUS_NEW
      ) {
        this.recoverableFlag = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GST_OPERATING_UNIT) {
        this.ouName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, periodType) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.periodType = periodType;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.GST_OPERATING_UNIT) {
        this.parent_value_set_id = this.selectedLegalEntity.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD
      ) {
        this.parent_value_set_id = this.selectedLegalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    // advanceSearch(params) {
    //   this.params = params;
    //   this.selectedLegalEntity.value = params.le_id;
    //   this.selectedITCType.value = params.itc_type;
    //   this.selectedGSTNumber.value = params.gst_number;
    //   this.selectedGSTNumber.text = params.gst_number;
    //   this.selectedPeriodFrom.text = params.period_from;
    //   this.selectedPeriodFrom.value = params.period_from;
    //   this.selectedPeriodTo.text = params.period_to;
    //   this.selectedPeriodTo.value = params.period_to;
    //   this.itcReconciliationGridViewList();
    //   this.showAdvSearchModal = false;
    // },
    clear() {
      this.gstRecoType = {
        value: null,
        text: null
      };
      this.selectedLegalEntity = {
        value: null,
        text: null
      };
      this.selectedITCType = {
        value: null,
        text: null
      };
      this.selectedGSTNumber = {
        value: null,
        text: null
      };
      this.selectedPeriodFrom = {
        value: null,
        text: null
      };
      this.selectedPeriodTo = {
        value: null,
        text: null
      };
      this.searchParams = null;
      this.requestParams = {};
      this.invoiceNumber = null;
      this.supplierGstn = null;
      this.reconcileStatus = {
        value: null,
        text: null
      };

      this.glDateFrom = null;
      this.glDateTo = null;
      this.operatingUnit = null;
      this.vendorName = null;
      this.vendorPan = null;
      this.correctInvoice = null;
      this.invTypeLookup = this.defaultValue;
      this.recoverableFlag = this.defaultValue;
      this.itc_claimed = null;
      this.itc_unclaimed = null;
      this.ouName = this.defaultValue;
      this.totalRows = null
    },
    showHideAdvSearchModal(flag) {
      this.showAdvSearchModal = flag;
    },
    autoReconcile() {},
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    hideChildModal() {},
    rowSelected() {},
    totalRowsCount(item) {
      this.totalRows = item;
    },
    mainSearch() {
      this.params = null;
      this.itcReconciliationGridViewList();
    },
    clearVsetValues(vsetCode, periodType) {
      if (vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN) {
        this.selectedGSTNumber = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity = {
          value: null,
          text: null
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_RECONCILATION_STATUS
      ) {
        this.reconcileStatus = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GST_RECO_TYPE) {
        this.gstRecoType = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (periodType === 'periodFrom') {
          this.selectedPeriodFrom = {
            value: null,
            text: null
          };
        } else if (periodType === 'periodTo') {
          this.selectedPeriodTo = {
            value: null,
            text: null
          };
        }
      } else if (vsetCode === this.invTypeLookup.value) {
        this.invTypeLookup = this.defaultValue;
      } else if (
        vsetCode === appStrings.VALUESETTYPE.GST_RECONCILATION_STATUS_NEW
      ) {
        this.recoverableFlag = this.defaultValue;
      } else if (vsetCode === this.ouName.value) {
        this.ouName = this.defaultValue;
      } else if (vsetCode === 'suppName') {
        this.supplierName = null;
      }
    },
    manualReconciliation() {
      if (
        this.selectedLegalEntity.value &&
        this.selectedITCType.value &&
        this.selectedGSTNumber.value &&
        this.selectedPeriodFrom.value &&
        this.selectedPeriodTo.value
      ) {
        this.showExcelUploadModal = true;
      } else {
        this.$bvToast.toast('Kindly select all the mandatory fields.', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    selectedTab(tab) {
      this.totalRows = null;
      this.activeTab = tab;
    },
    manualRecoDowmload() {
      this.loader = true;
      /**
       * @param(payload, 'action name', 'file name')
       */
      const downloadpayload = {
        ITC_TYPE: this.selectedITCType.value,
        gst_number: this.selectedGSTNumber.value,
        le_id: this.selectedLegalEntity.value,
        period_from: this.selectedPeriodFrom.value,
        period_to: this.selectedPeriodTo.value
      };
      downloadpayload._limit = 10000;
      this.downloadExcel.downloadData(
        downloadpayload,
        'itcReconciliation/getItcGstrSixAdata',
        'ITC_Manual_Recon_Format',
        () => (this.loader = false)
      );
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};