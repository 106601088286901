import invoiceMatch from './invoiceMatch';
import gstrTwoASixAMatch from './gstrTwoASixAMatch';
import journalMatch from './journalMatch';
import manualSearch from './manualSearch';
import distributionTax from './distributionTax';
import grnMatch from './grnMatch';
import nearMatch from './nearMatch';
import customInvMatch from './customInvMatch';
export default {
  name: 'available',
  components: {
    invoiceMatch,
    gstrTwoASixAMatch,
    journalMatch,
    manualSearch,
    distributionTax,
    grnMatch,
    nearMatch,
    customInvMatch
  },
  props: ['dtlId', 'invoiceNum', 'legalEntityId'],
  data() {
    return {
      payload: null,
      unsubscribe: null,
      loader: false,
      availableData: [
        {
          gstn_no: null,
          gstn_no_supp: null,
          invoice_number: null,
          invoice_date: null,
          invoice_value: null,
          taxable_value: null,
          igst_amount: null,
          cgst_amount: null,
          sgst_amount: null,
          rate: null,
          invoice_type: null,
          inv_num: null
        }
      ],
      searchData: {
        invNum: null,
        legalEntityId: null,
        dtl_id: null
      }
    };
  },
  mounted() {
    this.searchData.invNum = this.invoiceNum;
    this.searchData.legalEntityId = this.legalEntityId;
    this.searchData.dtl_id = this.dtlId;
    this.getReconcileDetails();
  },
  methods: {
    getReconcileDetails() {
      this.loader = true;
      this.$store
        .dispatch('itcReconciliation/getReconcileDetails', this.dtlId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.availableData = response.data.data.page[0];
            // this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};