import DatePicker from 'vue2-datepicker';
export default {
    name: 'journalMatch',
    components: {
        DatePicker,
    },
    data() {
        return {
            loader: false,
            unsubscribe: null,
            docNumFrom: null,
            docNumTo: null,
            dateFrom: null,
            dateTo: null,
            amountFrom: null,
            amountTo: null,
            lineNumFrom: null,
            lineNumTo: null,
            description: null,
            journalMatchData: [],
            journalMatchFields: [
                {
                    key: 'select'
                },
                {
                    key: 'document_number'
                },
                {
                    key: 'line_number'
                },
                {
                    key: 'period_name'
                },
                {
                    key: 'gl_date',
                    label: 'GL Date'
                },
                {
                    key: 'sgst',
                    label: 'SGST'
                },
                {
                    key: 'cgst',
                    label: 'CGST'
                },
                {
                    key: 'igst',
                    label: 'IGST'
                },
                {
                    key: 'cess',
                    label: 'CESS'
                },
                {
                    key: 'account'
                },
                {
                    key: 'description'
                },
                {
                    key: 'remark'
                }
            ]
        };
    },
    mounted() {},
    methods: {
        journalMatchList() {

        },
    },
};