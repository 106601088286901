export default {
    name: 'recoError',
    components: {
    },
    data() {
        return {
            payload: null,
            unsubscribe: null,
            loader: false,
            recoErrorData: [],
            recoErrorFields: [
                {
                    key: 'fp_gstn_2a',
                    label: 'FP GSTN 2A'
                },
                {
                    key: 'tp_gstn_2a',
                    label: 'TP GSTN 2A'
                },
                {
                    key: 'invoice_number_2a',
                    label: 'Invoice Number 2A'
                },
                {
                    key: 'invoice_value_2a',
                    label: 'Invoice Value 2A'
                },
                {
                    key: 'taxable_value_2a',
                    label: 'Taxable Value 2A'
                },
                {
                    key: 'igst_2a',
                    label: 'IGST 2A'
                },
                {
                    key: 'cgst_2a',
                    label: 'CGST 2A'
                },
                {
                    key: 'sgst_2a',
                    label: 'SGST 2A'
                },
                {
                    key: 'error_description'
                },
                {
                    key: 'erp_vendor_num',
                    label: 'ERP Vendor Num'
                },
                {
                    key: 'erp_vendor_name',
                    label: 'ERP Vendor Num'
                },
                {
                    key: 'trx_type'
                }, 
                {
                    key: 'fp',
                    label: 'FP'
                },
                {
                    key: 'tp',
                    label: 'TP'
                },
                {
                    key: 'doc_num'
                },
                {
                    key: 'receipt_num'
                },
                {
                    key: 'tax_invoice_num'
                },
                {
                    key: 'invoice_value'
                },
                {
                    key: 'taxable_value'
                },
                {
                    key: 'igst',
                    label: 'CGST'
                },
                {
                    key: 'cgst',
                    label: 'SGST'
                },
                {
                    key: 'sgst',
                    label: 'SGST'
                }
            ],
        };
    },
    mounted( ) {},
    methods: {},
    // beforeDestroy() {
    //     this.unsubscribe();
    // }
};