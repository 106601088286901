var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"fixed":"top"}},[(_vm.loader)?_c('div',{staticClass:"overlay"},[_c('b-spinner',{staticClass:"loader",attrs:{"variant":"primary","label":"Spinning"}})],1):_vm._e(),_c('b-row',[_c('div',{staticStyle:{"width":"100vw","position":"relative"}},[_c('b-button',{staticStyle:{"position":"absolute","right":"6px"},attrs:{"variant":"success"},on:{"click":function($event){return _vm.updateIms()}}},[_vm._v("Update")])],1)]),_c('b-row',[_c('b-col',{staticClass:"mt-4 text-centre pl-3",attrs:{"md":"12"}},[_c('div',{},[_c('b-table',{staticClass:"bg-white",staticStyle:{"max-height":"calc(80vh - 250px) !important"},attrs:{"striped":"","hover":"","bordered":"","outlined":"","items":_vm.imsData,"fields":_vm.imsFields,"show-empty":"","sticky-header":""},scopedSlots:_vm._u([{key:"cell(ims_status)",fn:function(data){return [_c('span',{class:data.item.action === 'A' ||
                data.item.action === 'R' ||
                data.item.action === 'P'
                  ? 'color'
                  : ''},[(
                  data.item.action === 'A' ||
                    data.item.action === 'R' ||
                    data.item.action === 'P'
                )?_c('b-icon',{attrs:{"icon":"check2","aria-hidden":"true","font-scale":"2.5"}}):_vm._e()],1)]}},{key:"cell(approve)",fn:function(data){return [_c('b-form-checkbox',{key:data.index,attrs:{"disabled":data.item.action === 'A',"value":true,"unchecked-value":false},on:{"change":function($event){return _vm.selectedRadio(data.index, 'A')}},model:{value:(data.item.approve),callback:function ($$v) {_vm.$set(data.item, "approve", $$v)},expression:"data.item.approve"}})]}},{key:"cell(reject)",fn:function(data){return [_c('b-form-checkbox',{key:data.index,attrs:{"disabled":data.item.action === 'R',"value":true,"unchecked-value":false},on:{"change":function($event){return _vm.selectedRadio(data.index, 'R')}},model:{value:(data.item.reject),callback:function ($$v) {_vm.$set(data.item, "reject", $$v)},expression:"data.item.reject"}})]}},{key:"cell(pending)",fn:function(data){return [_c('b-form-checkbox',{key:data.index,attrs:{"disabled":data.item.action === 'P',"value":true,"unchecked-value":false},on:{"change":function($event){return _vm.selectedRadio(data.index, 'P')}},model:{value:(data.item.pending),callback:function ($$v) {_vm.$set(data.item, "pending", $$v)},expression:"data.item.pending"}})]}}])})],1)])],1),_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.searchParams._limit,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }