export default {
    name: 'paymentView',
    components: {
    },
    data() {
        return {
            payload: null,
            unsubscribe: null,
            loader: false,
            paymentViewData: [],
            paymentViewFields: [
                {
                    key: 'line_Number'
                },
                {
                    key: 'gstn',
                    label: 'GSTN'
                },
                {
                    key: 'gstn_supplier',
                    label: 'GSTN Supplier'
                },
                {
                    key: 'supplier_name'
                },
                {
                    key: 'invoice_number'
                },
                {
                    key: 'invoice_date'
                },
                {
                    key: 'invoice_value'
                },
                {
                    key: 'taxable_value'
                },
                {
                    key: 'igst',
                    label: 'IGST'
                },
                {
                    key: 'cgst',
                    label: 'CGST'
                },
                {
                    key: 'sgst',
                    label: 'SGST'
                },
                {
                    key: 'cess',
                    label: 'CESS'
                },
                {
                    key: 'line_status'
                },
                {
                    key: 'reconciled_taxable_amount'
                },
                {
                    key: 'igst_reconcile',
                    label: 'IGST Reconcile'
                },
                {
                    key: 'cgst_reconcile',
                    label: 'CGST Reconcile'
                },
                {
                    key: 'sgst_reconcile',
                    label: 'SGST Reconcile'
                },
                {
                    key: 'cess_reconcile',
                    label: 'CESS Reconcile'
                }
            ],
            data: [],
            fields: [
                {
                    key: 'invoice_number'
                },
                {
                    key: 'invoice_amount'
                },
                {
                    key: 'amount_paid'
                },
                {
                    key: 'amount_unpaid'
                },
                {
                    key: 'vendor_name'
                },
                {
                    key: 'invoice_date'
                },
                {
                    key: 'gl_date',
                    label: 'GL Date'
                },
                {
                    key: 'ageing'
                }
            ]
        };
    },
    mounted( ) {},
    methods: {},
    // beforeDestroy() {
    //     this.unsubscribe();
    // }
};