import DatePicker from 'vue2-datepicker';
export default {
    name: 'gstrTwoASixAMatch',
    components: {
        DatePicker,
    },
    data() {
        return {
            loader: false,
            unsubscribe: null,
            year: null,
            periodname: null,
            lineNumFrom: null,
            lineNumTo: null,
            amountFrom: null,
            amountTo: null,
            gstrTwoASixAMatchData: [],
            gstrTwoASixAMatchFields: [
                {
                    key: 'select'
                },
                {
                    key: 'gstn',
                    label: 'GSTN'
                },
                {
                    key: 'gstn_supplier',
                    label: 'GSTN Supplier'
                },
                {
                    key: 'vendor_name'
                },
                {
                    key: 'invoice_number'
                },
                {
                    key: 'voucher_number'
                },
                {
                    key: 'line_number'
                },
                {
                    key: 'period_name'
                },
                {
                    key: 'invoice_date'
                },
                {
                    key: 'invoice_amount'
                },
                {
                    key: 'taxable_amount'
                },
                {
                    key: 'igst_amount',
                    label: 'IGST Amount'
                },
                {
                    key: 'cgst_amount',
                    label: 'CGST Amount'
                },
                {
                    key: 'sgst_amount',
                    label: 'SGST Amount'
                },
                {
                    key: 'cess_amount',
                    label: 'CESS Amount'
                },
                {
                    key: 'remark'
                }
            ]
        };
    },
    mounted() {},
    methods: {
        gstrTwoASixAMatchList() {
            
        },
    },
};