import DatePicker from 'vue2-datepicker';
export default {
    name: 'grnMatch',
    components: {
        DatePicker,
    },
    data() {
        return {
            loader: false,
            unsubscribe: null,
            taxInvoiceDateFrom: null,
            taxInvoiceDateTo: null,
            amountFrom: null,
            amountTo: null,
            taxInvoiceNum: null,
            receiptNum: null,
            grnMatchData: [],
            grnMatchFields: [
                {
                    key: 'select'
                },
                {
                    key: 'gstn',
                    label: 'GSTN'
                },
                {
                    key: 'gstn_vendor',
                    label: 'GSTN Vendor'
                },
                {
                    key: 'vendor_name'
                },
                {
                    key: 'invoice_number'
                },
                {
                    key: 'tax_invoice_number'
                },
                {
                    key: 'tax_invoice_date'
                },
                {
                    key: 'gl_date',
                    label: 'GL Date'
                },
                {
                    key: 'voucher_number'
                },
                {
                    key: 'invoice_amount'
                },
                {
                    key: 'taxable_amount'
                },
                {
                    key: 'igst',
                    label: 'IGST'
                },
                {
                    key: 'cgst',
                    label: 'CGST'
                },
                {
                    key: 'sgst',
                    label: 'SGST'
                },
                {
                    key: 'cess',
                    label: 'CESS'
                },
                {
                    key: 'tax_rate'
                },
                {
                    key: 'remark'
                }
            ]
        };
    },
    mounted() {},
    methods: {
        grnMatchList() {

        },
    },
};