import commonHelper from '@/app/utility/common.helper.utility';
import ReconcileDetails from '../gstrTwoASixADetails/reconcileDetails';
import Available from '../gstrTwoASixADetails/available';
import appStrings from '@/app/utility/string.utility';

export default {
  name: 'books',
  props: ['searchParams'],
  components: {
    ReconcileDetails,
    Available
  },
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.getBooks(this.searchParams);
      } else {
        this.booksData = [];
      }
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage - 1;
      this.getBooks(this.searchParams);
    }
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      booksData: [],
      showModal: false,
      tab: null,
      dtlId: null,
      booksFields: [
        {
          key: 'selectBox',
          label: 'Select',
          variant: 'info',
          stickyColumn: true
        },
        {
          key: 'username',
          class: 'col-fix'
        },
        {
          key: 'operating_unit',
          class: 'col-fix'
        },
        {
          key: 'vendor_name',
          class: 'col-fix'
        },
        {
          key: 'first_party_gst',
          class: 'col-fix'
        },
        {
          key: 'third_party_gst',
          class: 'col-fix'
        },
        {
          key: 'period_name',
          class: 'col-fix'
        },
        {
          key: 'source',
          class: 'col-fix'
        },
        {
          key: 'voucher_no',
          class: 'col-fix'
        },
        {
          key: 'invoice_type_lookup_code',
          class: 'col-fix'
        },
        {
          key: 'gl_date',
          label: 'GL Date',
          class: 'col-fix'
        },
        {
          key: 'invoice_number',
          class: 'col-fix'
        },
        {
          key: 'invoice_date',
          class: 'col-fix'
        },
        {
          key: 'invoice_amount',
          class: 'col-fix'
        },
        {
          key: 'taxable_amount',
          class: 'col-fix'
        },
        {
          key: 'invoice_amount',
          class: 'col-fix'
        },
        {
          key: 'rate',
          class: 'col-fix'
        },
        {
          key: 'total_tax',
          class: 'col-fix'
        },
        {
          key: 'igst',
          label: 'IGST',
          class: 'd-total-tax col-fix',
          stickyColumn: true,
          variant: 'success'
        },
        {
          key: 'cgst',
          label: 'CGST',
          class: 'd-total-tax col-fix',
          stickyColumn: true,
          variant: 'success'
        },
        {
          key: 'sgst',
          label: 'SGST',
          class: 'd-total-tax col-fix',
          stickyColumn: true,
          variant: 'success'
        },
        // {
        //   key: 'trx_line_id',
        //   class: 'col-fix'
        // },
        // {
        //   key: 'invoice_id',
        //   class: 'col-fix'
        // },
        {
          key: 'recoverable_flag',
          class: 'col-fix'
        },
        {
          key: 'reverse_Charge',
          class: 'col-fix'
        },
        {
          key: 'correction_status',
          class: 'col-fix'
        },
        {
          key: 'correct_fp_gstn',
          label: 'Correct FP GSTN',
          class: 'd-correction-status col-fix',
          stickyColumn: true,
          variant: 'success'
        },
        {
          key: 'correct_tp_gstn',
          label: 'Correct TP GSTN',
          class: 'd-correction-status col-fix',
          stickyColumn: true,
          variant: 'success'
        },
        {
          key: 'correct_invoice_num',
          class: 'd-correction-status col-fix',
          stickyColumn: true,
          variant: 'success'
        },
        {
          key: 'correct_invoice_date',
          class: 'd-correction-status col-fix',
          stickyColumn: true,
          variant: 'success'
        },
        {
          key: 'reconciliation_status',
          class: 'col-fix'
        },
        {
          key: 'errors',
          class: 'col-fix'
        },
        {
          key: 'invoice_num_error',
          class: 'd-total-line col-fix',
          stickyColumn: true,
          variant: 'success'
        },
        {
          key: 'tax_type_error',
          class: 'd-total-line col-fix',
          stickyColumn: true,
          variant: 'success'
        },
        {
          key: 'taxable_value_error',
          class: 'd-total-line col-fix',
          stickyColumn: true,
          variant: 'success'
        },
        {
          key: 'fp_gstn_error',
          label: 'FP GSTN Error',
          class: 'd-total-line col-fix',
          stickyColumn: true,
          variant: 'success'
        },
        {
          key: 'tp_gstn_error',
          label: 'TP GSTN Error',
          class: 'd-total-line col-fix',
          stickyColumn: true,
          variant: 'success'
        },
        {
          key: 'total_amt_claimed',
          label: 'Total Claimed Amount',
          class: 'col-fix'
        },
        {
          key: 'claimed_igst',
          label: 'Claimed IGST',
          class: 'col-fix'
        },
        {
          key: 'claimed_cgst',
          label: 'Claimed CGST',
          class: 'col-fix'
        },
        {
          key: 'claimed_sgst',
          label: 'Claimed SGST',
          class: 'col-fix'
        },
        {
          key: 'itc_claimed',
          class: 'col-fix',
          variant: 'success'
        },
        {
          key: 'itc_unclaimed',
          class: 'col-fix',
          variant: 'success'
        }
        // {
        //   key: 'reconcile_details',
        //   class: 'col-fix'
        // },
        // {
        //   key: 'available',
        //   class: 'col-fix'
        // },
      ],
      selectedRow: {},
      selectedRows: [],
      responseMsg: '',
      isSuccess: false,
      showAlert: false,
      selectAllCheckBox: false,
      showExcelUploadModal: false
    };
  },
  mounted() {
    if (this.searchParams.le_id) {
      this.getBooks(this.searchParams);
    }
  },
  methods: {
    showUploadExcelModal() {
      this.showExcelUploadModal = true;
    },
    getBooks(params) {
      let payload = this.searchParams;
      if (params) {
        payload = Object.assign(payload, params);
      }
      this.loading = true;
      this.$store
        .dispatch('itcReconciliation/getBooks', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.booksData = response.data.data.page;
            this.booksData.map(ele => {
              ele.total_amt_claimed = ele.total_amt_claimed
                ? ele.total_amt_claimed
                : 0;
              ele.claimed_igst = ele.claimed_igst ? ele.claimed_igst : 0;
              ele.claimed_cgst = ele.claimed_cgst ? ele.claimed_cgst : 0;
              ele.claimed_sgst = ele.claimed_sgst ? ele.claimed_sgst : 0;
              ele.csgstFlag =
                ele.claimed_cgst || ele.claimed_sgst ? true : false;
              ele.igstFlag = ele.claimed_igst ? true : false;
            });
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    calTotalClaimedAmt(index, item, type) {
      if (item.claimed_cgst || item.claimed_sgst) {
        this.booksData[index].csgstFlag = true;
        if (type == 'cgst') {
          this.booksData[index].claimed_sgst = item.claimed_cgst;
        } else if (type == 'sgst') {
          this.booksData[index].claimed_cgst = item.claimed_sgst;
        }
      } else {
        this.booksData[index].csgstFlag = false;
      }

      if (
        !this.booksData[index].claimed_cgst &&
        !this.booksData[index].claimed_cgst
      ) {
        this.booksData[index].claimed_cgst = 0;
        this.booksData[index].claimed_sgst = 0;
        this.booksData[index].csgstFlag = false;
      }

      if (item.claimed_igst) {
        this.booksData[index].igstFlag = true;
      } else {
        this.booksData[index].igstFlag = false;
      }

      if (!this.booksData[index].claimed_igst) {
        this.booksData[index].claimed_igst = 0;
        this.booksData[index].igstFlag = false;
      }

      this.booksData[index].total_amt_claimed =
        +item.claimed_igst + +item.claimed_cgst + +item.claimed_sgst;
      this.booksData = JSON.parse(JSON.stringify(this.booksData));
    },
    expandColumn(data, class1, class2) {
      let elem = document.querySelectorAll(`.${class1}`);
      if (elem && elem[0] && elem[0].classList.contains(class1)) {
        if (data === 'errors') {
          elem.forEach(el => {
            el.classList.replace('d-total-line', class2);
          });
        } else if (data === 'correction_status') {
          elem.forEach(el => {
            el.classList.replace('d-correction-status', class2);
          });
        } else if (data === 'total_tax') {
          elem.forEach(el => {
            el.classList.replace('d-total-tax', class2);
          });
        } else if (data === 'ITC_CLAIMED') {
          elem.forEach(el => {
            el.classList.replace('d-itc-claimed', class2);
          });
        }
      } else {
        if (data === 'errors') {
          let otherElem = document.querySelectorAll('.other-total-line');
          otherElem.forEach(el => {
            el.classList.replace('other-total-line', 'd-total-line');
          });
        } else if (data === 'correction_status') {
          let otherElem = document.querySelectorAll('.other-correction-status');
          otherElem.forEach(el => {
            el.classList.replace(
              'other-correction-status',
              'd-correction-status'
            );
          });
        } else if (data === 'total_tax') {
          let otherElem = document.querySelectorAll('.other-total-tax');
          otherElem.forEach(el => {
            el.classList.replace('other-total-tax', 'd-total-tax');
          });
        } else if (data === 'ITC_CLAIMED') {
          let otherElem = document.querySelectorAll('.other-itc-claimed');
          otherElem.forEach(el => {
            el.classList.replace('other-itc-claimed', 'd-itc-claimed');
          });
        }
      }
    },
    openShowModal(flag, component, item) {
      this.showModal = flag;
      if (component && item) {
        this.tab = component;
        this.dtlId = item.dtl_id;
      }
      this.setChildName(this.tab);
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ReconcileDetails') {
        return (this.childName = 'Reconcile Details');
      } else if (tab === 'Available') {
        return (this.childName = 'Available');
      }
    },
    selectBoxChecked(flag, index, item, invoiceId, rate) {
      this.booksData[index].selectBox = flag;
      if (flag) {
        this.selectedRows.push(item);
      } else {
        this.selectedRows = this.selectedRows.filter(
          el => el.invoice_id + el.rate !== invoiceId + rate
        );
      }
    },
    selectAllBoxChecked(flag) {
      this.selectedRows = [];
      if (flag) {
        this.booksData = this.booksData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRows = this.booksData;
      } else {
        this.booksData = this.booksData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRows = [];
      }
    },
    claimed() {
      const filterClaimed = this.booksData.filter(el => el.selectBox);
      if (filterClaimed.length) {
        let data = [];
        for (let i of filterClaimed) {
          data.push({
            invoice_id: i.invoice_id,
            claimed_igst: +i.claimed_igst,
            claimed_cgst: +i.claimed_cgst,
            claimed_sgst: +i.claimed_sgst,
            total_amt_claimed: +i.total_amt_claimed,
            tax_rate_percentage: i.rate,
            trx_line_id: i.trx_line_id
          });
        }
        const payload = {
          getparams: data
        };
        this.loading = true;
        this.$store
          .dispatch('itcReconciliation/updateBooksClaimed', payload)
          .then(resp => {
            this.loading = false;
            if (resp.status === 200) {
              this.$bvToast.toast(resp.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
              this.getBooks();
            } else {
              this.$bvToast.toast(resp.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loading = false;
            this.$bvToast.toast(appStrings.autoFailedMsg, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          });
      } else {
        this.$bvToast.toast('Kindly select invoices first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    unclaimed() {
      const filterUnClaimed = this.booksData.filter(el => el.selectBox);
      if (filterUnClaimed.length) {
        let data = [];
        for (let i of filterUnClaimed) {
          data.push({
            invoice_id: i.invoice_id,
            claimed_igst: 0,
            claimed_cgst: 0,
            claimed_sgst: 0,
            total_amt_claimed: 0,
            tax_rate_percentage: i.rate,
            trx_line_id: i.trx_line_id
          });
        }
        const payload = {
          getparams: data
        };
        this.loading = true;
        this.$store
          .dispatch('itcReconciliation/updateBooksUnclaimed', payload)
          .then(resp => {
            this.loading = false;
            if (resp.status === 200) {
              this.$bvToast.toast(resp.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
              this.getBooks();
            } else {
              this.$bvToast.toast(resp.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.$bvToast.toast('Kindly select invoices first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    }
  }
};
