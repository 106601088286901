import DatePicker from 'vue2-datepicker';
export default {
    name: 'invoiceMatch',
    components: {
        DatePicker,
    },
    data() {
        return {
            loader: false,
            unsubscribe: null,
            invoiceDateFrom: null,
            invoiceDateTo: null,
            invoiceNum: null,
            customInvMatchData: [],
            customInvMatchFields: [
                {
                    key: 'select'
                },
                {
                    key: 'gstn',
                    label: 'GSTN'
                },
                {
                    key: 'third_party_gstn',
                    label: 'Third Party GSTN'
                },
                {
                    key: 'vendor_name'
                },
                {
                    key: 'invoice_number'
                },
                {
                    key: 'invoice_date'
                },
                {
                    key: 'taxable_amount'
                },
                {
                    key: 'igst',
                    label: 'IGST'
                },
                {
                    key: 'cgst',
                    label: 'CGST'
                },
                {
                    key: 'sgst',
                    label: 'SGST'
                },
                {
                    key: 'cess',
                    label: 'CESS'
                },
                {
                    key: 'tax_rate'
                },
            ]
        };
    },
    mounted() {},
    methods: {
        customInvMatchList() {

        },
    },
};