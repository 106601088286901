import { BasicSelect } from 'vue-search-select';

export default {
  name: 'dropdown',
  components: {
    BasicSelect
  },
  data() {
    return {
      selectedType: {
        value: 'Reconciliation',
        text: 'Reconciliation',
        link: ''
      },
      typeList: [
        {
          value: 'Reconciliation',
          text: 'Reconciliation',
          url: '/dashboard/gstAdmin/itcReconciliation'
        },
        {
          value: 'Updation',
          text: 'Updation',
          url: '/dashboard/gstAdmin/itcDataUpdation'
        },
        {
          value: 'Forego',
          text: 'Forego'
        },
        {
          value: 'DN(Debit Note)',
          text: 'DN(Debit Note)'
        },
        {
          value: 'ITC Eligible',
          text: 'ITC Eligible'
        },
        {
          value: 'Reclaim',
          text: 'Reclaim'
        }
      ]
    };
  },
  mounted() {},
  methods: {
    selectOption(event) {
      this.$router.push(event.url);
    }
  }
};
