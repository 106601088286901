export default {
    name: 'foregoDetails',
    components: {
    },
    data() {
        return {
            payload: null,
            unsubscribe: null,
            loader: false,
            foregoDetailsData: [],
            foregoDetailsFields: [
                {
                    key: 'line_Number'
                },
                {
                    key: 'gstn',
                    label: 'GSTN'
                },
                {
                    key: 'gstn_supplier',
                    label: 'GSTN Supplier'
                },
                {
                    key: 'supplier_name'
                },
                {
                    key: 'invoice_number'
                },
                {
                    key: 'invoice_date'
                },
                {
                    key: 'invoice_value'
                },
                {
                    key: 'taxable_value'
                },
                {
                    key: 'igst',
                    label: 'IGST'
                },
                {
                    key: 'cgst',
                    label: 'CGST'
                },
                {
                    key: 'sgst',
                    label: 'SGST'
                },
                {
                    key: 'cess',
                    label: 'CESS'
                },
                {
                    key: 'line_status'
                },
                {
                    key: 'reconciled_taxable_amount'
                },
                {
                    key: 'igst_reconcile',
                    label: 'IGST Reconcile'
                },
                {
                    key: 'cgst_reconcile',
                    label: 'CGST Reconcile'
                },
                {
                    key: 'sgst_reconcile',
                    label: 'SGST Reconcile'
                },
                {
                    key: 'cess_reconcile',
                    label: 'CESS Reconcile'
                }
            ],
            summaryForegoData: [],
            summaryForegoFields: [
                {
                    key:'a',
                    label: 'IGST'
                },
                {
                    key:'b',
                    label: 'CGST'
                },
                {
                    key:'c',
                    label: 'SGST'
                },
                {
                    key:'d',
                    label: 'IGST'
                },
                {
                    key:'e',
                    label: 'CGST'
                },
                {
                    key:'f',
                    label: 'SGST'
                },
                {
                    key:'g',
                    label: 'IGST'
                },
                {
                    key:'h',
                    label: 'CGST'
                },
                {
                    key:'i',
                    label: 'SGST'
                },
                {
                    key:'j',
                    label: 'IGST'
                },
                {
                    key:'k',
                    label: 'CGST'
                },
                {
                    key:'l',
                    label: 'SGST'
                },
                {
                    key:'m',
                    label: 'IGST'
                },
                {
                    key:'n',
                    label: 'CGST'
                },
                {
                    key:'o',
                    label: 'SGST'
                },
                {
                    key:'p',
                    label: 'IGST'
                },
                {
                    key:'q',
                    label: 'CGST'
                },
                {
                    key:'r',
                    label: 'SGST'
                }
            ],
            data: [],
            fields: [
                {
                    key: 'select',
                },
                {
                    key: 'gstn_supplier',
                    label: 'GSTN Supplier'
                },
                {
                    key: 'reconcile_reference_type'
                },
                {
                    key: 'invoice_number'
                },
                {
                    key: 'taxable_value'
                },
                {
                    key: 'igst',
                    label: 'IGST'
                },
                {
                    key: 'cgst',
                    label: 'CGST'
                },
                {
                    key: 'sgst',
                    label: 'SGST'
                },
                {
                    key: 'invoice_number'
                },
                {
                    key: 'invoice_amount'
                },
                {
                    key: 'igst',
                    label: 'IGST'
                },
                {
                    key: 'cgst',
                    label: 'CGST'
                },
                {
                    key: 'sgst',
                    label: 'SGST'
                },
                {
                    key: 'rate'
                },
                {
                    key: 'adjustment_type'
                },
                {
                    key: 'adjustment_reference_type'
                },
                {
                    key: 'adjustment_nature'
                },
                {
                    key: 'line_type'
                },
                {
                    key: 'period_name'
                },
                {
                    key: 'line_Number'
                },
                {
                    key: 'parent_line'
                },
                
                {
                    key: 'supplier_name'
                },
                
                
               
                
                {
                    key: 'tax_type'
                },
                
                {
                    key: 'cess',
                    label: 'CESS'
                },
                {
                    key: 'line_status'
                },
               
                {
                    key: 'invoice_type'
                },
                {
                    key: 'reverse_charge'
                },
                {
                    key: 'reconciled_taxable_amount'
                },
                {
                    key: 'igst_reconcile',
                    label: 'IGST Reconcile'
                },
                {
                    key: 'cgst_reconcile',
                    label: 'CGST Reconcile'
                },
                {
                    key: 'sgst_reconcile',
                    label: 'SGST Reconcile'
                },
                {
                    key: 'cess_reconcile',
                    label: 'CESS Reconcile'
                },
                
                {
                    key: 'manual_reco_remark'
                },
                {
                    key: 'itc_nr',
                    label: 'ITC NR'
                },
                {
                    key: 'gstr2agstr6a_remark',
                    label: 'GSTR2A/GSTR6A Remark'
                },
                {
                    key: 'reconcile_details'
                },
                {
                    key: 'available'
                },
                {
                    key: 'payment_view'
                },
                {
                    key: 'forego_details'
                },
                {
                    key: 'reco_errors'
                }
            ]
        };
    },
    mounted( ) {},
    methods: {},
    // beforeDestroy() {
    //     this.unsubscribe();
    // }
};